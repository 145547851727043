
import {useStore} from "vuex"

export default {
	name: "ReviewPage",
	setup() {
		const store = useStore()

		// initialize form record from storage
		store.dispatch("form_data/loadFormRecordList")
		const formRecord = store.getters["form_data/getFormRecord"]

		const experience = store.getters["profile/getExperience"]
		const assignment = store.getters["profile/getAssignment"]
		const location = store.getters["form_data/getLocation"]()
		const originatingAgencyIdentifier = store.getters["session/getOriginatingAgencyIdentifier"]
		const randomId = store.getters["session/getRandomId"]

		// TODO alert if form id is missing
		// TODO alert if assignment is missing
		// TODO alert if ORI is empty or missing or not 9 chars or not end in 00
		// TODO alert if random ID is empty or missing

		return { formRecord, experience, assignment, originatingAgencyIdentifier, randomId, location }
	}
}
