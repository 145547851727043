
import {ref} from "vue"
import {mapGetters, useStore} from "vuex"
import router from "@/router"

import useEmitter from "@/composables/UseEmitter.js"

import FormHeader from "@/components/FormHeader.vue"
import ReviewPage from "@/components/ReviewPage.vue"
import FormEdit from "@/common/form_edit/FormEdit.vue"
import store from "@/store";
import moment from "moment"


export default {
	name: "Review",
	components: {ReviewPage, FormHeader, FormEdit},
	setup() {
		const store = useStore()
		const emitter = useEmitter()

		const formId = store.getters["form/getFormId"]

		let formEditKey = ref(0)

		function goHome() {
			router.push("/home")
		}

		function goBack() {
			let lastPage = Number.parseInt(store.getters["form/maxPages"] + "") - 1
			router.push("/form/" + formId + "/page/" + lastPage)
		}

		function addPerson() {
			//store.dispatch("form_data/createPersonRecord")
			store.dispatch("form_data/duplicatePersonRecord")
			router.push("/form/000001/page/2")
		}

		function save() {
			let startTime = JSON.parse(localStorage.getItem('startTime'))
			let diff = moment().diff(startTime)
			let d = moment.duration(diff)
			// console.log(d.hours() + ':' + d.minutes() + ':' + d.seconds())
			// console.log((d.hours()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})  + ':' + (d.minutes()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ':' + (d.seconds()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}))
			let time_to_complete =  (d.hours()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ':' + (d.minutes()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ':' + (d.seconds()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) 
			// store.dispatch("form_data/setItemValue", {
			// 	"store":"",
			// 	"key":"time_to_complete",
			// 	"value": diff.toString()
			// })
			let params ={
				route: "/start",
				timeDiff: time_to_complete
			}
			emitter.emit("save-formedit", params)
		}

		function duplicatePerson() {
			store.dispatch("form_data/duplicatePersonRecord")
			router.push("/form/000001/page/2")
		}

		function loadSubmissionForEdit() {
			let formData = store.getters["form_data/getFormRecord"]

			if (!formData) {
				return
			}

			if (store.getters["form_data/getActiveFormRecordStatus"] !== "Saved") {
				store.dispatch("form_data/setItemValue", {
					"store":"",
					"key":"status",
					"value":"Not Saved"
				})
			}

			store.dispatch("submission/convert", formData).then((result) => {
				store.dispatch("submission/set", result).then(() => {
					formEditKey.value = Math.random()
				})
			})
		}

		loadSubmissionForEdit()

		return { formEditKey, addPerson, goHome, goBack, save, duplicatePerson }
	},
	computed: {
		...mapGetters("form", [
			"getFormId", "maxPages"
		])
	}
}
